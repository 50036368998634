<template>
    <qrcode-stream @decode="onDecode" @init="onInit" />
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {

  components: { QrcodeStream },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data () {
    return {
      result: '',
      error: '',
      camera: 'rear'
    }
  },

  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onDecode (result) {
      this.result = result
      if (this.result !== '') {
        this.$emit('qr-function', result)
      }
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          alert('ERROR: you need to grant camera access permission')
        } else if (error.name === 'NotFoundError') {
          alert('ERROR: no camera on this device')
        } else if (error.name === 'NotSupportedError') {
          alert('ERROR: secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          alert('ERROR: is the camera already in use? Try to refresh the page')
        } else if (error.name === 'OverconstrainedError') {
          alert('ERROR: installed cameras are not suitable')
        } else if (error.name === 'captureApiNotSupportedError') {
          alert('ERROR: Stream API is not supported in this browser')
        } else if (error.name === 'InsecureContextError') {
          alert('ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.')
        } else {
          alert(`ERROR: Camera error (${error.name})`)
        }
      }
    }
  }
}
</script>
